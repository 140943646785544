<template>
    <el-drawer :title="formTitle" size="50%" :visible.sync="formVisible">
        <el-form :model="dataForm" ref="dataForm" label-width="120px" :rules="formRules">
            <el-form-item label="训练名称:" prop="Name">
                <el-input v-model="dataForm.Name" placeholder="请输入训练计划名称"></el-input>
            </el-form-item>
            <el-form-item label="开始日期:" prop="BeginDate">
                <el-date-picker v-model="dataForm.BeginDate" :disabled="readOnly" type="date" size="small"
                    placeholder="请选择开始日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="结束日期:" prop="EndDate">
                <el-date-picker v-model="dataForm.EndDate" :disabled="readOnly" type="date" size="small"
                    placeholder="请选择结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="开放报名:" prop="AllowJoin">
                <el-switch v-model="dataForm.AllowJoin">
                </el-switch>
            </el-form-item>
            <el-form-item label="报名说明:" prop="Description">
                <el-input v-model="dataForm.Description" auto-complete="off" placeholder="请输入本训练报名说明"
                    :disabled="readOnly"></el-input>
            </el-form-item>
            <el-form-item label="训练次数:" prop="Count">
                <el-input-number v-model="dataForm.Count" auto-complete="off" 
                    :disabled="readOnly"></el-input-number>
            </el-form-item>
            <el-form-item label="锻炼时间:" prop="Times">
                <el-input type="textarea" v-model="dataForm.Times" auto-complete="off" placeholder="每行输入一个时间段"
                    :disabled="readOnly"></el-input>
            </el-form-item>
            <el-form-item v-if="!readOnly">
                <div class="footer">
                    <el-button type="primary" @click="handleSubmit">提交</el-button>
                </div>
            </el-form-item>
        </el-form>
    </el-drawer>
</template>
<script>
import { save } from "@/api/camp/camp";
export default {
    components: {
    },
    props: {
    },
    data() {
        return {
            readOnly: false,
            formVisible: false,
            formTitle: "新增",
            dataForm: {
            },
            formRules: {
                Name: [
                    { required: true, message: "请输入名称", trigger: "change" },
                ],
                Count:[
                    { required: true, message: "请输入次数", trigger: "change" },
                ],
                Times: [{ required: true, message: "请输入锻炼时间段", trigger: "change" },],
                Description: [{ required: true, message: "请输入报名说明", trigger: "change" }],
                BeginDate: [
                    { required: true, message: "请输入开始日期", trigger: "change" },
                ],
                EndDate: [{ required: true, message: "请输入结束日期", trigger: "change" },],
            },
        };
    },
    methods: {
        handleView(row) {
            this.readOnly = true;
            this.dataForm = JSON.parse(JSON.stringify(row));
            this.dataForm.BeginDate = this.format.dateFormat(row.BeginDate);
            this.dataForm.EndDate = this.format.dateFormat(row.EndDate);
            this.formTitle = "查看";
            this.formVisible = true;
            this.$nextTick(() => {
                this.$refs["dataForm"].clearValidate();
            });
        },
        handleCreate() {
            this.formTitle = "新增";
            this.formVisible = true;
            this.readOnly = false;
            this.dataForm = {

            };
            this.$nextTick(() => {
                this.$refs["dataForm"].clearValidate();
            });
        },
        handleUpdate(row) {
            this.dataForm = JSON.parse(JSON.stringify(row));
            this.dataForm.BeginDate = this.format.dateFormat(row.BeginDate);
            this.dataForm.EndDate = this.format.dateFormat(row.EndDate);
            this.readOnly = false;
            this.formTitle = "修改";
            this.formVisible = true;
            this.$nextTick(() => {
                this.$refs["dataForm"].clearValidate();
            });
        },
        handleSubmit: function () {
            this.$refs["dataForm"].validate((valid) => {
                if (valid) {
                    let data = Object.assign({}, this.dataForm);
                    save(data).then((response) => {
                        if (response) {
                            this.formVisible = false;
                            this.$emit("update");
                        }
                    });
                } else {
                    setTimeout(() => {
                        this.$refs["dataForm"].clearValidate();
                    }, 1000);
                }
            });
        },
    },
    mounted() { },
};
</script>
<style lang='scss' scoped>
/deep/.el-drawer__header {
    margin-bottom: 10px;
    font-size: 16px;
    color: #ff862f;
}

/deep/.el-drawer__body {
    overflow-y: scroll;
}

.footer {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
}

.invoice-container {
    padding: 10px;

    .input {
        width: 150px;
    }

    .tabs-container {
        /deep/.el-tabs--border-card>.el-tabs__content {
            padding: 0;
            height: 1px;
        }

        .tab-content {
            margin-top: -2px;
            border: 1px solid #dcdfe6;
            border-top: 0;
            padding: 15px;
            background: #fff;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
        }
    }
}
</style>