import request from '@/utils/request'

export function getPageList(data) {
  return request({
    url: 'camp/getPageList',
    method: 'post',
    data,
  })
}

export function getAll(data) {
  return request({
    url: 'camp/getAll',
    method: 'post',
    data,
  })
}

export function save(data) {
  return request({
    url: 'camp/save',
    method: 'post',
    data,
  })
}

export function del(data) {
  return request({
    url: 'camp/delete',
    method: 'post',
    data,
  })
}
